import React from 'react'

// Images
import leftArrowBlue from 'img/left_arrow_blue.svg'

// CSS
import { BlueStripe, H2, Image, P, StyledCustomLink } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'
import _ from 'lodash'

const AbsoluteImage = styled(Image)`
  right: -10px;
  top: 35px;

  @media (max-width: 767px) {
    right: 0;
    left: 0;
    top: 0;
    bottom: -120px;
    margin: auto;

    transform: rotate(90deg);
  }

  @media (max-width: 400px) {
    right: 0;
    left: 0;
    top: 0;
    bottom: -140px;
    margin: auto;

    transform: rotate(90deg);
  }
`

const StappenPlan = ({ className, length, content }) => (
  <div className={`container ${className ? `${className}` : ``}`}>
    <BlueStripe className="color-background-main mb-2" />
    <H2 className="font-size-xxl font-weight-xl mb-3">Autoschade: wat nu?</H2>
    <div className="row py-5 px-0 px-lg-5">
      {_.map(content, (item, index) => (
        <div key={index} className="col-12 col-md position-relative d-flex flex-wrap justify-content-center">
          <Image height={81} width={81} src={item.icon.localFile.publicURL} alt="" />
          {index < (length - 1) && (<AbsoluteImage className="position-absolute" src={leftArrowBlue} alt="" />)}
          <P className="text-center w-100 pt-4 pb-5 pb-md-0 font-weight-xl">{item.text}</P>
        </div>
      ))}
    </div>
    <div className="d-none justify-content-center">
      <StyledCustomLink hover className="color-background-contrast">
        <P className="font-weight-xl color-text-light">Download schadeformulier</P>
      </StyledCustomLink>
    </div>
  </div>
)

export default StappenPlan