import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import { Content } from 'components/StyledComponents'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import TitleBannerImage from 'components/TitleBannerImage'
import FooterBannerImage from 'components/FooterBannerImage'
import Specialist from 'components/Specialist'
import USP from 'components/USP'
import StappenPlan from 'components/StappenPlan'
import ContentWithImage from 'components/ContentWithImage'

// Third Party
import parse from 'html-react-parser'


const Autoschade = ({ pageContext }) => {
  const {
    autoschade: {
      title,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage1
            }
          }
        },
        autoschade,
        specialist,
        wat_nu: watNu,
        geavanceerde_spuiterij: geavanceerdeSpuiterij,
        usp,
        banner_image_2: bannerImage2,
        h1_tag: h1Tag
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`{
    autoschade: wordpressPage(wordpress_id: {eq: 9}) {
      title
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        autoschade
        specialist {
          cta_1 {
            target
            title
            url
          }
          cta_2 {
            target
            title
            url
          }
          text
          image_group {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          why {
            why_point
          }
        }
        wat_nu {
          text
          icon {
            localFile {
              publicURL
            }
          }
        }
        geavanceerde_spuiterij {
          content
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        usp {
          description
          title
        }
        banner_image_2 {
          title
          sub_title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }`)

  const watNuLength = watNu.length;

  return (
    <Layout subFooter>
      <SEO yoast={{ meta: yoast }} />
      <h1 className="d-none">{h1Tag}</h1>
      <TitleBannerImage image={bannerImage1} pageContext={pageContext} breadCrumbTitle={title} title={title} className="row position-relative d-flex align-items-end" />

      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-lg-7">
            <Content>
              {parse(autoschade)}
            </Content>
          </div>
          <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
            <Specialist specialist={specialist} />
          </div>
        </div>
      </div>

      <StappenPlan className="py-5" length={watNuLength} content={watNu} />

      <ContentWithImage className="py-5" content={geavanceerdeSpuiterij} />

      <USP className="color-background-secondary mt-5" usp={usp} />

      <FooterBannerImage bannerImage={bannerImage2} className="row position-relative" />
    </Layout>
  )
}

export default Autoschade